import { INITIAL_PAYMENT_SYSTEM } from 'constants/payment-system';

import { createSelector } from 'reselect';
import {
  paymentsSystemsResponseSelector,
  paymentsSystemResponseSelector,
  isPaymentSystemLoadingSelector,
} from 'api/payment-systems';
import type { CurrencyProtocols, PaymentSystem, PaymentSystemLimits } from 'types/api';

export const paymentSystemsSelector = createSelector(paymentsSystemsResponseSelector, (paymentSystemsResponse) => {
  return paymentSystemsResponse.payment_systems.map((paymentSystem) => {
    return {
      id: paymentSystem.id,
      casinoName: paymentSystem.casino.codename,
      childSystem: paymentSystem.name,
      systemName: paymentSystem.payment_provider.name,
      status: paymentSystem.status,
      tags: paymentSystem.tags.applied || [],
      error: paymentSystem.error,
    };
  });
});

export const paymentSystemsPaginationSelector = createSelector(
  paymentsSystemsResponseSelector,
  (paymentSystemsResponse) => {
    return paymentSystemsResponse.pagination;
  },
);

export const paymentSystemSelector = createSelector(
  isPaymentSystemLoadingSelector,
  paymentsSystemResponseSelector,
  (isPaymentSystemLoading, paymentSystem): PaymentSystem => {
    if (isPaymentSystemLoading || !paymentSystem) {
      return INITIAL_PAYMENT_SYSTEM;
    }

    const { limits, protocols } = paymentSystem.limits.reduce(
      (acc: { limits: PaymentSystemLimits; protocols: CurrencyProtocols }, limit) => {
        const { currency, data, protocols = [] } = limit;
        acc.limits[currency] = data;
        acc.protocols[currency] = protocols;
        return acc;
      },
      { limits: {}, protocols: {} },
    );

    const casino_settings = paymentSystem.access_data
      ? {
          [paymentSystem.casino.id]: {
            access_data: paymentSystem.access_data,
            payment_system_fee: paymentSystem.payment_system_fee,
            payment_system_fee_currency: paymentSystem.payment_system_fee_currency,
            payment_system_fee_percent: paymentSystem.payment_system_fee_percent,
          },
        }
      : {};

    const result = {
      id: paymentSystem.id,
      providerName: paymentSystem.payment_provider.name,
      systemName: paymentSystem.name,
      tags: paymentSystem.tags,
      testMode: paymentSystem.test_mode,
      currencies: paymentSystem.available_currencies,
      restrictions: JSON.stringify(paymentSystem.restrictions),
      casinos: [paymentSystem.casino.id],
      limits,
      protocols,
      casino_settings,
      hideSensitiveData: true,
      includePspService: true,
      jobName: paymentSystem.job_name,
      paymentGroups: paymentSystem.payment_groups.join(', '),
      excludedPaymentGroups: paymentSystem.excluded_payment_groups.join(', '),
    };

    return result;
  },
);
