import { cashflowsFilterKeys, CashflowsSearchParam, type CashflowsFilterKey } from 'constants/cashflows-search-params';

import React, { useCallback, useEffect, useState, type FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import DataGrid from 'components/molecules/DataGrid';
import {
  forceLoadCashflowsAction,
  forceLoadCashflowsFiltersAction,
  loadCashflowsFiltersAction,
  resetCashflowRequestAction,
  resetCreateCashflowAction,
  resetUpdateCashflowAction,
  resyncCashflowAction,
} from 'api/cashflows';
import AddButton from 'components/atoms/AddButton';
import { cashflowsFiltersSelector, cashflowsPaginationSelector, cashflowsSelector } from 'store/cashflows/selector';
import type { GridColDef, GridPaginationModel, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import Chip from 'components/atoms/Chip/Chip.styles';
import type { CashflowItem, SyncDetails } from 'types/api';
import { formatArrayToString } from 'utils/format-array-to-string';
import ActionBar from 'components/molecules/ActionBar';
import Drawer from 'components/molecules/Drawer/Drawer';
import { useSearchParams } from 'react-router-dom';
import { getCashflowsRequestParams } from 'utils/cashflows-filter-helper';
import FiltersBar from 'components/molecules/FiltersBar';
import ConfirmationDialog, {
  HighlightedContentText,
  WarningContentText,
} from 'components/molecules/ConfirmationDialog';
import NotificationBar from 'components/atoms/NotificationBar';
import SyncStatusInfo from 'components/molecules/SyncStatusInfo';
import { getSyncStatusButtonText, isEntityNonSynced } from 'utils/sync-status-helper';

import ActionButton from '../../atoms/ActionButton';
import { CashflowForm } from '../CashflowForm';

interface CashflowsProps {
  defaultFilters: Record<string, string[]>;
  defaultSortModel: GridSortModel;
}

const Cashflows: FC<CashflowsProps> = ({ defaultFilters, defaultSortModel }) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [cashflowId, setCashflowId] = useState<number>();
  const [isQuitFormConfirmationOpen, setIsQuitFormConfirmationOpen] = useState(false);
  const [isFormNotificationOpen, setIsFormNotificationOpen] = useState(false);
  const [isResyncConfirmationOpen, setIsResyncConfirmationOpen] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);

  const loadCashflows = useCallback(() => {
    const cashflowsRequestParams = getCashflowsRequestParams(searchParams);

    dispatch(forceLoadCashflowsAction(cashflowsRequestParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    loadCashflows();
  }, [dispatch, loadCashflows]);

  useEffect(() => {
    dispatch(loadCashflowsFiltersAction());
  }, [dispatch]);

  const cashflows = useSelector(cashflowsSelector);
  const pagination = useSelector(cashflowsPaginationSelector);
  const cashflowsFilters = useSelector(cashflowsFiltersSelector);

  const handlePaginationModelChange = (paginationModel: GridPaginationModel) => {
    setSearchParams(
      (prev) => {
        prev.set(CashflowsSearchParam.Page, (paginationModel.page + 1).toString());
        return prev;
      },
      { replace: true },
    );

    window.scrollTo({ top: 0 });
  };

  const handleSortingModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
    setSearchParams(
      (prev) => {
        const sortItem = newSortModel.length >= 0 ? newSortModel[0] : null;
        if (sortItem) {
          prev.set(CashflowsSearchParam.SortField, sortItem.field);
          prev.set(CashflowsSearchParam.SortDirection, sortItem.sort ?? '');
        } else {
          prev.delete(CashflowsSearchParam.SortField);
          prev.delete(CashflowsSearchParam.SortDirection);
        }

        return prev;
      },
      { replace: true },
    );
  };

  const onEditClick = (id: number) => {
    setCashflowId(id);
    setIsFormOpen(true);
    setIsEditMode(true);
  };

  const onAddClick = () => {
    setIsFormOpen(true);
    setIsEditMode(false);
  };

  const onResyncClick = (id: number) => {
    setCashflowId(id);
    setIsResyncConfirmationOpen(true);
  };

  const handleResyncConfirmationClose = () => {
    setIsResyncConfirmationOpen(false);
    setCashflowId(undefined);
  };

  const handleResync = () => {
    if (cashflowId) {
      dispatch(resyncCashflowAction(cashflowId));
      handleResyncConfirmationClose();
    }
  };

  const onCloseDrawer = () => {
    if (isFormDirty) {
      setIsQuitFormConfirmationOpen(true);
    } else {
      closeForm();
    }
  };

  const onCloseForm = () => {
    closeForm();
    setIsFormNotificationOpen(true);
    loadCashflows();
    dispatch(forceLoadCashflowsFiltersAction());
  };

  const handleQuitForm = () => {
    setIsQuitFormConfirmationOpen(false);
    closeForm();
  };

  const handleKeepEditingForm = () => {
    setIsQuitFormConfirmationOpen(false);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setCashflowId(undefined);
    setIsFormDirty(false);
    dispatch(resetCreateCashflowAction());
    dispatch(resetUpdateCashflowAction());
    dispatch(resetCashflowRequestAction());
  };

  const handleFormNotificationClose = () => {
    setIsFormNotificationOpen(false);
  };

  const searchCashflows = (filters: Record<string, string[]>) => {
    setSearchParams(
      (prev) => {
        Object.keys(cashflowsFilterKeys).forEach((key) => {
          const filterKey = key as CashflowsFilterKey;
          if (filters?.[filterKey]?.length) {
            prev.set(cashflowsFilterKeys[filterKey], filters[key].join(','));
          } else {
            prev.delete(cashflowsFilterKeys[filterKey]);
          }

          prev.delete(CashflowsSearchParam.Page);
        });

        return prev;
      },
      { replace: true },
    );
  };

  const resetFilters = () => {
    setSortModel([]);
    setSearchParams(
      (prev) => {
        Object.values(CashflowsSearchParam).forEach((key) => {
          prev.delete(key);
        });

        return prev;
      },
      { replace: true },
    );
  };

  const gridColumns: GridColDef[] = [
    {
      field: 'casino_name',
      headerName: 'Casino name',
      minWidth: 170,
    },
    {
      field: 'name',
      headerName: 'Cashflow name',
      minWidth: 170,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      minWidth: 105,
    },
    {
      field: 'acceptable_currencies',
      headerName: 'Acceptable currencies',
      minWidth: 230,
      sortable: false,
      flex: 1,
      renderCell: ({ value: acceptable_currencies }: GridRenderCellParams<CashflowItem, string[]>) => {
        return acceptable_currencies?.length ? (
          <Box display="flex" flexWrap="wrap" gap={0.5}>
            {acceptable_currencies.map((currency) => (
              <Chip key={currency} size="small" label={currency} variant="outlined" />
            ))}
          </Box>
        ) : null;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      minWidth: 120,
      cellClassName: 'ps-status-cell',
      renderCell: ({ row: { error, status } }: GridRenderCellParams<SyncDetails>) =>
        status && <SyncStatusInfo status={status} error={error} />,
    },
    {
      field: 'configure_childs',
      headerName: 'Configured childs',
      minWidth: 230,
      flex: 1,
      sortable: false,
      valueFormatter: (value: string[]) => formatArrayToString(value),
    },
    {
      field: 'actions',
      headerName: 'Action',
      minWidth: 105,
      sortable: false,
      renderCell: ({ row: { id, status } }: GridRenderCellParams<CashflowItem>) => (
        <Box display="flex" flexWrap="nowrap" justifyContent="center" gap="4px">
          <ActionButton onClick={() => onEditClick(id)}>Edit</ActionButton>
          <ActionButton disabled={!isEntityNonSynced(status)} onClick={() => onResyncClick(id)}>
            {getSyncStatusButtonText(status)}
          </ActionButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <ActionBar>
        <AddButton onClick={onAddClick}>Add cashflow</AddButton>
      </ActionBar>

      <FiltersBar
        filters={cashflowsFilters}
        defaultFilters={defaultFilters}
        onSubmit={searchCashflows}
        onResetFilters={resetFilters}
      />

      <DataGrid
        paginationMode="server"
        sortingMode="server"
        rowCount={pagination.total_count}
        paginationModel={{
          pageSize: pagination.per_page,
          page: pagination.current_page - 1,
        }}
        onPaginationModelChange={handlePaginationModelChange}
        sortModel={sortModel}
        onSortModelChange={handleSortingModelChange}
        columns={gridColumns}
        rows={cashflows}
        autoRowHeight
      />

      <Drawer title={isEditMode ? 'Edit cashflow' : 'New cashflow'} open={isFormOpen} onClose={onCloseDrawer}>
        <CashflowForm
          cashflowId={cashflowId}
          isEditMode={isEditMode}
          setIsFormDirty={setIsFormDirty}
          onClose={onCloseForm}
        />
      </Drawer>

      <ConfirmationDialog
        isOpen={isResyncConfirmationOpen}
        onClose={handleResyncConfirmationClose}
        title="Resync cashflow"
        confirmButtonText="Resync"
        cancelButtonText="Cancel"
        onConfirm={handleResync}
        onCancel={handleResyncConfirmationClose}
      >
        Do you want to resync <HighlightedContentText>cashflow?</HighlightedContentText>
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={isQuitFormConfirmationOpen}
        onClose={handleKeepEditingForm}
        title={`Quit ${isEditMode ? 'editing' : 'creating'} cashflow?`}
        confirmButtonText="Quit"
        cancelButtonText={`Keep ${isEditMode ? 'editing' : 'creating'}`}
        onConfirm={handleQuitForm}
        onCancel={handleKeepEditingForm}
      >
        <WarningContentText>All entered data will be lost.</WarningContentText>
      </ConfirmationDialog>

      <NotificationBar type="success" isOpen={isFormNotificationOpen} onClose={handleFormNotificationClose}>
        {`Cashflow has been ${isEditMode ? 'updated' : 'created'} successfully`}
      </NotificationBar>
    </>
  );
};

export default Cashflows;
