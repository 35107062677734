import { INITIAL_CASHFLOW } from 'constants/cashflow';

import {
  cashflowResponseSelector,
  isCashflowLoadingSelector,
  isCreateCashflowCompletedSelector,
  isUpdateCashflowCompletedSelector,
} from 'api/cashflows';
import { createSelector } from 'reselect';
import type { Cashflow } from 'types/entities';
import { mapCommissionsToCasflowChilds } from 'utils/mappers';

export const cashflowSelector = createSelector(
  isCashflowLoadingSelector,
  cashflowResponseSelector,
  (isCashflowLoading, cashflow): Cashflow => {
    if (isCashflowLoading || !cashflow) {
      return INITIAL_CASHFLOW;
    }

    const result: Cashflow = {
      id: cashflow.id,
      acceptableCurrencies: cashflow.acceptable_currencies,
      childSystemName: cashflow.child_system_name,
      currency: cashflow.currency,
      name: cashflow.name,
      casinos: [cashflow.casino_id],
      jobName: cashflow.job_name,
      providerName: cashflow.system_name,
      childs: mapCommissionsToCasflowChilds(cashflow.commissions),
    };

    return result;
  },
);

export const isSaveCashflowCompletedSelector = createSelector(
  [isCreateCashflowCompletedSelector, isUpdateCashflowCompletedSelector],
  (isCreateCashflowCompleted, isUpdateCashflowCompleted) => isCreateCashflowCompleted || isUpdateCashflowCompleted,
);
