import { getStatusText } from 'utils/sync-status-helper';

export const mapValuesToOptions = (values: string[]) => {
  return values.map((value) => ({
    label: value,
    value,
  }));
};

export const mapStatusesToOptions = (statuses: string[]) => {
  return statuses.map((value) => ({
    label: getStatusText(value),
    value,
  }));
};
