import { ListItemHeight } from 'constants/multi-select';

import { Box, Chip, FormControl, TextField, type Theme } from '@mui/material';
import { Checkbox, Divider, ListItemText as MuiListItemText, ListSubheader, Select, styled } from '@mui/material';

export const StyledFormControl = styled(FormControl)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.background.paper,

  '& .MuiFormLabel-root': {
    color: theme.palette.text.secondary,
  },

  '& .MuiInputBase-root': {
    color: theme.palette.text.dark,
  },

  '& .MuiSelect-select.Mui-disabled': {
    color: theme.palette.text.disabledControl,
    backgroundColor: theme.palette.background.disabledControl,
  },

  '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.background.style2}`,
  },
}));

export const MultiSelectContentBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'limitTags',
})(({ theme, limitTags }) => ({
  display: 'flex',
  flexWrap: limitTags ? 'nowrap' : 'wrap',
  gap: theme.spacing(0.5),
}));

export const StyledSelect = styled(Select)({
  '& .MuiInputBase-input': {
    minHeight: '24px', // to fit Chips components inside
  },
}) as typeof Select;

export const StyledCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    fontSize: '1.25rem',
  },
}) as typeof Checkbox;

export const StyledListSubheader = styled(ListSubheader)(() => ({
  position: 'sticky',
  top: 0,
  height: ListItemHeight.Search,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '9px',
  paddingRight: '9px',
})) as typeof ListSubheader;

export const StyledDivider = styled(Divider)(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const ListItemText = styled(MuiListItemText)(({ theme }: { theme: Theme }) => ({
  whiteSpace: 'initial',
  color: theme.palette.text.dark,

  '& .MuiListItemText-primary': {
    lineHeight: 1,
  },
}));

export const StyledChip = styled(Chip)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.text.dark,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  '&.Mui-disabled': {
    opacity: 1,
    '& .MuiChip-deleteIcon': {
      display: 'none',
    },
  },
})) as typeof Chip;

export const StyledRestChip = styled(Chip)(({ theme }: { theme: Theme }) => ({
  flexShrink: 0,
  color: theme.palette.text.dark,
})) as typeof Chip;

export const StyledTextField = styled(TextField)(({ theme }: { theme: Theme }) => ({
  '& .MuiInputBase-root': {
    color: theme.palette.text.dark,
  },
}));
