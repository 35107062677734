import { DEFAULT_TAB_NAME, PERCENT_COMMISSIONS } from 'constants/cashflow';
import { PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY } from 'constants/payment-system';

import type { CashflowChild, Commission, Commissions } from 'types/entities';
import { addCustomError } from 'utils/validation-helper';
import { z } from 'zod';

export const validateChildSystemName = (systemName: string, providerName: string, ctx: z.RefinementCtx) => {
  if (!systemName) {
    const validationMessage = PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY.includes(providerName)
      ? 'Enter child system name'
      : 'Select child system name';
    addCustomError(ctx, validationMessage, ['childSystemName']);
  }
};

const validateCurrencies = (ctx: z.RefinementCtx, data: CashflowChild) => {
  if (data.depositReceivedCurrencies.length > 0 && data.depositSentCurrencies.length === 0) {
    addCustomError(ctx, 'Select deposit sent currency / cashout received currency', ['depositSentCurrencies']);
  }

  if (data.depositSentCurrencies.length > 0 && data.depositReceivedCurrencies.length === 0) {
    addCustomError(ctx, 'Select deposit received currency / cashout sent currency', ['depositReceivedCurrencies']);
  }
};

const validateCommissions = (ctx: z.RefinementCtx, data: CashflowChild) => {
  Object.entries(data.commissions).forEach(([type, commissionTypes]: [string, Commissions]) => {
    Object.entries(commissionTypes).forEach(([commissionKey, commission]: [string, Commission]) => {
      const isPercentCommission = PERCENT_COMMISSIONS.includes(commissionKey);

      if (!commission.currency && !isPercentCommission) {
        addCustomError(ctx, 'Select currency', [`commissions.${type}.${commissionKey}.currency`]);
      }

      if (isPercentCommission && commission.value > 100) {
        addCustomError(ctx, 'Enter percent that is less than or equal to 100', [
          `commissions.${type}.${commissionKey}.value`,
        ]);
      }
    });
  });
};

const findDuplicateChildsByName = (childs: CashflowChild[]) => {
  const nameMap = new Map<string, number[]>();

  childs.forEach((child, index) => {
    const name = child.optionChildSystemName;

    if (name.length === 0 || index === 0) {
      return;
    }

    if (!nameMap.has(name)) {
      nameMap.set(name, []);
    }

    nameMap.get(name)!.push(index);
  });

  return Array.from(nameMap.values()).filter((indexes) => indexes.length > 1);
};

const findDuplicateChildsByCurrencies = (childs: CashflowChild[], duplicateChilds: Array<number[]>) => {
  const result: number[] = [];

  duplicateChilds.forEach((indexes) => {
    // Map to store unique currency combinations and their associated child indexes
    const currenciesMap = new Map<string, number[]>();

    indexes.forEach((index) => {
      const child = childs[index];
      // Sort and concatenate the received and sent currencies to create a unique key
      const depositReceivedCurrencies = child.depositReceivedCurrencies.sort().join();
      const depositSentCurrencies = child.depositSentCurrencies.sort().join();
      const currenciesKey =
        depositReceivedCurrencies.length > 0 && depositSentCurrencies.length > 0
          ? `${depositReceivedCurrencies},${depositSentCurrencies}`
          : '';

      // Empty currencies are not duplicate
      if (currenciesKey.length === 0) {
        return;
      }

      if (!currenciesMap.has(currenciesKey)) {
        currenciesMap.set(currenciesKey, []);
      }

      currenciesMap.get(currenciesKey)!.push(index);
    });

    // Add indexes with more than one child (duplicates by currencies) to the result
    currenciesMap.forEach((indexes) => {
      if (indexes.length > 1) {
        result.push(...indexes);
      }
    });
  });

  return result;
};

const findIntersectingChilds = (childs: CashflowChild[], duplicateChilds: Array<number[]>) => {
  const result: number[] = [];

  duplicateChilds.forEach((indexes) => {
    const origins = indexes.map((index) => childs[index].origins);
    const trustedLevels = indexes.map((index) => childs[index].trustedLevels);
    const licenses = indexes.map((index) => childs[index].licenses);

    // Find intersections by `origins`
    for (let i = 0; i < origins.length; i++) {
      for (let j = i + 1; j < origins.length; j++) {
        const origins1 = origins[i];
        const origins2 = origins[j];

        const areOriginsEmpty = origins1.length === 0 && origins2.length === 0;

        // Check if there is an intersection or both arrays are empty
        if (areOriginsEmpty || origins1.some((origin) => origins2.includes(origin))) {
          // Find intersection by `trustedLevels`
          const trustedLevels1 = trustedLevels[i];
          const trustedLevels2 = trustedLevels[j];

          const areTrustedLevelsEmpty = trustedLevels1.length === 0 && trustedLevels2.length === 0;

          // Check if there is an intersection or both arrays are empty
          if (areTrustedLevelsEmpty || trustedLevels1.some((trustedLevel) => trustedLevels2.includes(trustedLevel))) {
            // Find intersection by `licenses`
            const licenses1 = licenses[i];
            const licenses2 = licenses[j];

            const areLicencesEmpty = licenses1.length === 0 && licenses2.length === 0;

            // If there is an intersection or both arrays are empty, add indixes to the result
            if (areLicencesEmpty || licenses1.some((license) => licenses2.includes(license))) {
              if (!result.includes(indexes[i])) {
                result.push(indexes[i]);
              }
              if (!result.includes(indexes[j])) {
                result.push(indexes[j]);
              }
            }
          }
        }
      }
    }
  });

  return result;
};

const commission = z.object({
  value: z.number().default(0),
  currency: z.string(),
});

const percentCommission = z.object({
  value: z.number().default(0),
});

const commissions = z.object({
  commission_fixed: commission,
  commission_percent: percentCommission,
  min_commission: commission,
});

const extendeCommissions = commissions.extend({
  failed_commission_fixed: commission,
  max_commission: commission,
});

const child = z
  .object({
    optionChildSystemName: z.string().min(1, 'Enter option child system name'),
    trustedLevels: z.array(z.string()),
    origins: z.array(z.string()),
    licenses: z.array(z.string()),
    depositReceivedCurrencies: z.array(z.string()),
    depositSentCurrencies: z.array(z.string()),
    id: z.number(),
    commissions: z.object({
      deposit: extendeCommissions,
      cashout: extendeCommissions,
      chargeback: commissions,
      refund: commissions,
      reversal: commissions,
    }),
  })
  .superRefine((data, ctx) => {
    if (data.optionChildSystemName !== DEFAULT_TAB_NAME) {
      validateCurrencies(ctx, data);
      validateCommissions(ctx, data);
    }
  });

const createBaseCashflowSchema = (isEditMode: boolean) => {
  const baseCashflowSchema = z.object({
    id: z.number().int(),
    name: z.string().min(1, 'Enter cashflow name'),
    providerName: z.string().min(1, 'Select system name'),
    casinos: z.array(z.number()).min(1, 'Select casino name'),
    currency: z.string().min(1, 'Select currency'),
    childSystemName: z.string(),
    jobName: z.string().min(1, 'Enter request details'),
    acceptableCurrencies: z.array(z.string()).min(1, 'Select acceptable currencies'),
    childs: z
      .array(child)
      .optional()
      .superRefine((data, ctx) => {
        if (data) {
          const duplicateChilds = findDuplicateChildsByName(data);
          const duplicateChildsByCurrencies = findDuplicateChildsByCurrencies(data, duplicateChilds);
          const duplicateChildsByCurrenciesMessage =
            'Currency pairs must be unique for the same option child system name';

          duplicateChildsByCurrencies.forEach((index) => {
            addCustomError(ctx, duplicateChildsByCurrenciesMessage, [`${index}.depositSentCurrencies`]);
            addCustomError(ctx, duplicateChildsByCurrenciesMessage, [`${index}.depositReceivedCurrencies`]);
          });

          const intersectingChilds = findIntersectingChilds(data, duplicateChilds);
          const intersectingChildsMessage = isEditMode
            ? 'The set of trusted levels, origins and licenses must be unique for the same option child system names'
            : 'The set of origins and trusted levels must be unique for the same option child system names';

          intersectingChilds.forEach((index) => {
            addCustomError(ctx, intersectingChildsMessage, [`${index}.origins`]);
            addCustomError(ctx, intersectingChildsMessage, [`${index}.trustedLevels`]);
            if (isEditMode) {
              addCustomError(ctx, intersectingChildsMessage, [`${index}.licenses`]);
            }
          });
        }
      }),
  });

  return baseCashflowSchema;
};

export const createCashflowSchema = (isEditMode: boolean) => {
  const cashflowSchema = createBaseCashflowSchema(isEditMode);

  return cashflowSchema.superRefine((data, ctx) =>
    validateChildSystemName(data.childSystemName, data.providerName, ctx),
  );
};
