import React from 'react';
import Cashflows from 'components/organisms/Cashflows';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { getCashflowsFilters, getCashflowsSortModel } from 'utils/cashflows-filter-helper';

export default function () {
  const [searchParams] = useSearchParams();

  const defaultFilters = getCashflowsFilters(searchParams);
  const defaultSortModel = getCashflowsSortModel(searchParams);

  return (
    <Routes>
      <Route index element={<Cashflows defaultFilters={defaultFilters} defaultSortModel={defaultSortModel} />} />
    </Routes>
  );
}
