import {
  cashflowsFilterKeys,
  CashflowsSearchParam,
  getSortFieldByPropertyName,
  type CashflowsFilterKey,
} from 'constants/cashflows-search-params';

import type { FilterConfig } from 'components/molecules/FiltersBar';
import type { CashflowsFiltersResponse, CashflowsRequestParams } from 'types/api';
import type { GridSortModel } from '@mui/x-data-grid';

import { mapStatusesToOptions, mapValuesToOptions } from './mappers';

const getCashflowsParams = (
  searchParams: URLSearchParams,
  asRequestParams: boolean,
): Record<string, string | string[]> => {
  const result: Record<string, string | string[]> = {};

  Object.keys(cashflowsFilterKeys).forEach((key) => {
    const filterKey = key as CashflowsFilterKey;
    const filterValue = searchParams.get(cashflowsFilterKeys[filterKey]);

    if (filterValue) {
      const key = asRequestParams ? cashflowsFilterKeys[filterKey] : filterKey;
      result[key] = asRequestParams ? filterValue : filterValue.split(',');
    }
  });

  return result;
};

export const getCashflowsFilters = (searchParams: URLSearchParams): Record<string, string[]> => {
  return getCashflowsParams(searchParams, false) as Record<string, string[]>;
};

export const getCashflowsSortModel = (searchParams: URLSearchParams): GridSortModel => {
  const sortModel: GridSortModel = [];

  const sortField = searchParams.get(CashflowsSearchParam.SortField) || '';
  const sortDirection = searchParams.get(CashflowsSearchParam.SortDirection) || '';

  if (sortField && (sortDirection === 'asc' || sortDirection === 'desc')) {
    sortModel.push({ field: sortField, sort: sortDirection });
  }

  return sortModel;
};

export const getCashflowsRequestParams = (searchParams: URLSearchParams): CashflowsRequestParams => {
  const params = getCashflowsParams(searchParams, true) as Record<string, string>;
  const page = searchParams.get(CashflowsSearchParam.Page);

  if (page) {
    params[CashflowsSearchParam.Page] = page;
  }

  const sortField = searchParams.get(CashflowsSearchParam.SortField);
  const sortDirection = searchParams.get(CashflowsSearchParam.SortDirection);

  const sorting = sortField && sortDirection ? `${getSortFieldByPropertyName(sortField)} ${sortDirection}` : '';

  if (sorting) {
    params[CashflowsSearchParam.Sorting] = sorting;
  }

  return params;
};

export const getCashflowsFiltersOptions = (
  cashflowsFiltersResponse: CashflowsFiltersResponse,
): Record<string, FilterConfig> => {
  const filters = {
    cashflowNames: {
      id: 'cashflow-names',
      label: 'Cashflow names',
      options: mapValuesToOptions(cashflowsFiltersResponse.cashflow_names),
    },
    currencies: {
      id: 'currencies',
      label: 'Currencies',
      options: mapValuesToOptions(cashflowsFiltersResponse.currencies),
    },
    acceptableCurrencies: {
      id: 'acceptable-currencies',
      label: 'Acceptable currencies',
      options: mapValuesToOptions(cashflowsFiltersResponse.acceptable_currencies),
    },
    statuses: {
      id: 'status',
      label: 'Status',
      options: mapStatusesToOptions(cashflowsFiltersResponse.statuses),
    },
    configuredChilds: {
      id: 'configured-childs',
      label: 'Configured childs',
      options: mapValuesToOptions(cashflowsFiltersResponse.configured_childs),
    },
    origins: {
      id: 'origins',
      label: 'Origins',
      options: mapValuesToOptions(cashflowsFiltersResponse.origins),
    },
    licenseNames: {
      id: 'license-names',
      label: 'License names',
      options: mapValuesToOptions(cashflowsFiltersResponse.licenses),
    },
  };

  return filters;
};
