import { LIMIT_FIELDS, LIMITS_LABELS, MIN_FIELDS, MIN_MAX_MAP, PERCENT_LIMITS } from 'constants/limits';
import { DEFAULT_CASINO, PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY } from 'constants/payment-system';

import { FieldType, type AccessData, type AccessDataField } from 'types/api';
import { z } from 'zod';
import forge from 'node-forge';
import { addCustomError } from 'utils/validation-helper';
import { getUniqueValuesByDelimiter } from 'utils/getUniqueValuesByDelimiter';

interface FeeFields {
  payment_system_fee?: number | null | undefined;
  payment_system_fee_currency?: string | null | undefined;
  payment_system_fee_percent?: number | null | undefined;
}

const isValidJson = (value?: string): boolean => {
  if (!value) {
    return true;
  }

  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
};

const validatePercents = (ctx: z.RefinementCtx, key: string, val?: number) => {
  if (PERCENT_LIMITS.includes(key)) {
    if (val && val > 100) {
      addCustomError(ctx, 'Enter commission that is less than or equal to 100');
    }
  }
};

const validateMinMaxValues = (data: Record<string, Record<string, number | undefined>>, ctx: z.RefinementCtx) => {
  MIN_FIELDS.forEach((minKey) => {
    if (data[minKey]) {
      const maxKey = MIN_MAX_MAP[minKey];

      LIMIT_FIELDS.forEach((field) => {
        const minValue = data[minKey][field] ?? 0;
        const maxValue = data[maxKey][field] ?? 0;

        if (minValue > maxValue) {
          const minLabel = LIMITS_LABELS[minKey];
          const maxLabel = LIMITS_LABELS[maxKey];

          const message = `${minLabel} value should be less than or equal to ${maxLabel} value`;

          addCustomError(ctx, message, [minKey, field]);
          addCustomError(ctx, message, [maxKey, field]);
        }
      });
    }
  });
};

const baseLimitSchema = z.record(
  z.string(),
  z
    .record(
      z.string(),
      z.record(
        z.string(),
        z
          .number()
          .optional()
          .superRefine((val, ctx) => {
            const key = ctx.path[ctx.path.length - 2] as string;

            validatePercents(ctx, key, val);
          }),
      ),
    )
    .superRefine((data, ctx) => validateMinMaxValues(data, ctx)),
);

const isAccessDataFieldEmpty = (value: unknown) => {
  const result =
    value === null ||
    value === undefined ||
    value === '' ||
    value === false ||
    (Array.isArray(value) && value.length === 0);

  return result;
};

const isValidPemFormat = (value: string) => {
  const pemHeader = `-----BEGIN.*KEY-----`;
  const pemFooter = `-----END.*KEY-----`;

  const pemFormatRegex = new RegExp(`^${pemHeader}[ \\t]*(\\n{1,2})([^\n]+)([\\s\\S]*?)([^\n]+)(\\n)${pemFooter}`);

  return pemFormatRegex.test(value);
};

const isValidRsaKey = (name: string, value: string) => {
  if (!isValidPemFormat(value)) {
    return false;
  }

  try {
    name.includes('public') ? forge.pki.publicKeyFromPem(value) : forge.pki.privateKeyFromPem(value);
    return true;
  } catch {
    return false;
  }
};

const validateAccessDataFields = (fields: AccessDataField[], data: Record<string, unknown>, ctx: z.RefinementCtx) => {
  fields.forEach((field) => {
    if (
      (field.required && isAccessDataFieldEmpty(data[field.name])) ||
      (field.requiredIf.length > 0 &&
        !isAccessDataFieldEmpty(data[field.requiredIf[0]]) &&
        isAccessDataFieldEmpty(data[field.name]))
    ) {
      const message = `${field.type === FieldType.Array || field.enum ? 'Select' : 'Enter'} ${field.label}`;
      addCustomError(ctx, message, [field.name]);
    } else if (field.isRsaKey && !isValidRsaKey(field.name, data[field.name] as string)) {
      addCustomError(ctx, 'RSA key is invalid', [field.name]);
    }
  });
};

const validateFeeFields = (ctx: z.RefinementCtx, data: FeeFields) => {
  if (data.payment_system_fee && !data.payment_system_fee_currency) {
    addCustomError(ctx, 'Select payment system fee currency', ['payment_system_fee_currency']);
  }

  if (data.payment_system_fee_currency && data.payment_system_fee === undefined) {
    addCustomError(ctx, 'Enter payment system fee', ['payment_system_fee']);
  }

  if (data.payment_system_fee_percent === undefined) {
    addCustomError(ctx, 'Enter payment system fee percent', ['payment_system_fee_percent']);
  }

  if (data.payment_system_fee_percent && data.payment_system_fee_percent > 100) {
    addCustomError(ctx, 'Enter value that is less than or equal to 100', ['payment_system_fee_percent']);
  }
};

const createAccessDataSchema = (fields: AccessDataField[]) => {
  return z.record(z.string(), z.unknown()).superRefine((data, ctx) => {
    const key = ctx.path[ctx.path.length - 2];

    if (Number(key) === DEFAULT_CASINO.id) {
      return;
    }

    validateAccessDataFields(fields, data, ctx);
  });
};

const validateSystemName = (ctx: z.RefinementCtx, systemName: string, providerName: string) => {
  if (!systemName) {
    const validationMessage = PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY.includes(providerName)
      ? 'Enter child system'
      : 'Select child system';
    addCustomError(ctx, validationMessage, ['systemName']);
  }
};

const validatePaymentGroups = (ctx: z.RefinementCtx, paymentGroups?: string, excludedPaymentGroups?: string) => {
  if (paymentGroups && excludedPaymentGroups) {
    const paymentGroupsArray = getUniqueValuesByDelimiter(paymentGroups);
    const excludedPaymentGroupsArray = getUniqueValuesByDelimiter(excludedPaymentGroups);

    const duplicates = paymentGroupsArray.filter((group) => excludedPaymentGroupsArray.includes(group));

    if (duplicates.length > 0) {
      const message = 'Duplicate group names are not allowed';

      addCustomError(ctx, message, ['paymentGroups']);
      addCustomError(ctx, message, ['excludedPaymentGroups']);
    }
  }
};

export const basePaymentSystemSchema = z.object({
  id: z.number().int(),
  providerName: z.string().min(1, 'Select payment system'),
  systemName: z.string(),
  restrictions: z
    .string()
    .optional()
    .refine((value) => isValidJson(value), {
      message: 'Query contains syntax errors. Enter a valid query in JSON format',
    }),
  testMode: z.boolean().optional(),
  tags: z.object({
    applied: z.array(z.string()).optional(),
    excluded: z.array(z.string()).optional(),
  }),
  currencies: z.array(z.string()).optional(),
  casinos: z.array(z.number()).min(1, 'Select casino name'),
  limits: baseLimitSchema,
  protocols: z.record(z.string(), z.array(z.string())),
  paymentGroups: z.string().optional(),
  excludedPaymentGroups: z.string().optional(),
});

export const createPaymentSystemSchema = (isEditMode: boolean, accessData: AccessData) => {
  let paymentSystemSchema = basePaymentSystemSchema;

  if (accessData?.fields?.length > 0) {
    paymentSystemSchema = basePaymentSystemSchema.extend({
      casino_settings: z.record(
        z.string(),
        z
          .object({
            access_data: createAccessDataSchema(accessData.fields),
            payment_system_fee: z.number().nullable().optional(),
            payment_system_fee_currency: z.string().nullable().optional(),
            payment_system_fee_percent: z.number().nullable().optional(),
          })
          .superRefine((data, ctx) => {
            const key = ctx.path[ctx.path.length - 1];

            if (Number(key) !== DEFAULT_CASINO.id) {
              validateFeeFields(ctx, data);
            }
          }),
      ),
    });
  }

  if (!isEditMode) {
    paymentSystemSchema = paymentSystemSchema.extend({
      jobName: z.string().min(1, 'Enter request details'),
    });
  }

  return paymentSystemSchema.superRefine((data, ctx) => {
    validateSystemName(ctx, data.systemName, data.providerName);
    validatePaymentGroups(ctx, data.paymentGroups, data.excludedPaymentGroups);
  });
};
