import React from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import PaymentSystems from 'components/organisms/PaymentSystems';
import { getPaymentSystemsFilters, getPaymentSystemsSortModel } from 'utils/payment-systems-filter-helper';

export default function () {
  const [searchParams] = useSearchParams();

  const defaultFilters = getPaymentSystemsFilters(searchParams);
  const defaultSortModel = getPaymentSystemsSortModel(searchParams);

  return (
    <Routes>
      <Route index element={<PaymentSystems defaultFilters={defaultFilters} defaultSortModel={defaultSortModel} />} />
    </Routes>
  );
}
