import {
  type PaymentSystemFilterKey,
  PaymentSystemSearchParam,
  paymentSystemFilterKeys,
} from 'constants/payment-system-search-param';

import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import type { GridColDef, GridPaginationModel, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import DataGrid from 'components/molecules/DataGrid';
import {
  forceLoadPaymentSystemsAction,
  resetCreatePaymentSystemAction,
  resetPaymentSystemAction,
  resetUpdatePaymentSystemAction,
  resyncPaymentSystemAction,
} from 'api/payment-systems';
import AddButton from 'components/atoms/AddButton';
import FiltersBar from 'components/molecules/FiltersBar';
import { paymentSystemsSelector, paymentSystemsPaginationSelector } from 'store/payment-system/selectors';
import ConfirmationDialog, {
  HighlightedContentText,
  WarningContentText,
} from 'components/molecules/ConfirmationDialog';
import Drawer from 'components/molecules/Drawer';
import { getSyncStatusButtonText, isEntityNonSynced } from 'utils/sync-status-helper';
import { useSearchParams } from 'react-router-dom';
import { getPaymentSystemsRequestParams } from 'utils/payment-systems-filter-helper';
import { initPaymentSystemFiltersAction } from 'store/payment-systems';
import {
  filterChildSystemsSelector,
  filterCurrenciesSelector,
  filterPaymentProvidersSelector,
  filterStatusesSelector,
  filterTagsSelector,
} from 'store/payment-systems/selectors';
import NotificationBar from 'components/atoms/NotificationBar';
import { resetAccessDataAction } from 'api/access-data';
import ActionButton from 'components/atoms/ActionButton';
import ActionBar from 'components/molecules/ActionBar';
import type { SyncDetails } from 'types/api';
import SyncStatusInfo from 'components/molecules/SyncStatusInfo';

import { PaymentSystemForm } from '../PaymentSystemForm';

import PaymentSystemTags from './subcomponents/PaymentSystemTags';

interface PaymentSystemsProps {
  defaultFilters: Record<string, string[]>;
  defaultSortModel: GridSortModel;
}

const PaymentSystems: FC<PaymentSystemsProps> = ({ defaultFilters, defaultSortModel }) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [paymentSystemId, setPaymentSystemId] = useState<number>();
  const [isResyncConfirmationOpen, setIsResyncConfirmationOpen] = useState(false);
  const [isQuitFormConfirmationOpen, setIsQuitFormConfirmationOpen] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [isFormNotificationOpen, setIsFormNotificationOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const loadPaymentSystems = useCallback(() => {
    const paymentSystemRequestParams = getPaymentSystemsRequestParams(searchParams);

    dispatch(forceLoadPaymentSystemsAction(paymentSystemRequestParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    loadPaymentSystems();
    dispatch(initPaymentSystemFiltersAction());
  }, [loadPaymentSystems, dispatch]);

  const paymentSystems = useSelector(paymentSystemsSelector);
  const pagination = useSelector(paymentSystemsPaginationSelector);
  const filterPaymentProviders = useSelector(filterPaymentProvidersSelector);
  const filterChildSystems = useSelector(filterChildSystemsSelector);
  const filterTags = useSelector(filterTagsSelector);
  const filterCurrencies = useSelector(filterCurrenciesSelector);
  const filterStatuses = useSelector(filterStatusesSelector);

  const onResyncClick = useCallback((id: number) => {
    setPaymentSystemId(id);
    setIsResyncConfirmationOpen(true);
  }, []);

  const handleResyncConfirmationClose = useCallback(() => {
    setIsResyncConfirmationOpen(false);
    setPaymentSystemId(undefined);
  }, []);

  const handleResync = useCallback(() => {
    if (paymentSystemId) {
      dispatch(resyncPaymentSystemAction(paymentSystemId));
      handleResyncConfirmationClose();
    }
  }, [paymentSystemId, dispatch, handleResyncConfirmationClose]);

  const handlePaginationModelChange = (paginationModel: GridPaginationModel) => {
    setSearchParams(
      (prev) => {
        prev.set(PaymentSystemSearchParam.Page, (paginationModel.page + 1).toString());
        return prev;
      },
      { replace: true },
    );

    window.scrollTo({ top: 0 });
  };

  const handleSortingModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
    setSearchParams(
      (prev) => {
        const sortItem = newSortModel.length >= 0 ? newSortModel[0] : null;
        if (sortItem) {
          prev.set(PaymentSystemSearchParam.SortField, sortItem.field);
          prev.set(PaymentSystemSearchParam.SortDirection, sortItem.sort ?? '');
        } else {
          prev.delete(PaymentSystemSearchParam.SortField);
          prev.delete(PaymentSystemSearchParam.SortDirection);
        }

        return prev;
      },
      { replace: true },
    );
  };

  const onEditClick = useCallback((id: number) => {
    setPaymentSystemId(id);
    setIsFormOpen(true);
    setIsEditMode(true);
  }, []);

  const onAddClick = useCallback(() => {
    setIsFormOpen(true);
    setIsEditMode(false);
  }, []);

  const onCloseDrawer = () => {
    if (isFormDirty) {
      setIsQuitFormConfirmationOpen(true);
    } else {
      closeForm();
    }
  };

  const onCloseForm = () => {
    closeForm();
    setIsFormNotificationOpen(true);
    loadPaymentSystems();
  };

  const handleQuitForm = () => {
    setIsQuitFormConfirmationOpen(false);
    closeForm();
  };

  const handleKeepEditingForm = () => {
    setIsQuitFormConfirmationOpen(false);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setPaymentSystemId(undefined);
    setIsFormDirty(false);
    dispatch(resetUpdatePaymentSystemAction());
    dispatch(resetCreatePaymentSystemAction());
    dispatch(resetPaymentSystemAction());
    dispatch(resetAccessDataAction());
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'casinoName',
        headerName: 'Casino name',
        minWidth: 230,
      },
      {
        field: 'childSystem',
        headerName: 'Child system',
        minWidth: 180,
      },
      {
        field: 'systemName',
        headerName: 'System name',
        minWidth: 150,
      },
      {
        field: 'status',
        headerName: 'Status',
        align: 'center',
        minWidth: 120,
        cellClassName: 'ps-status-cell',
        renderCell: ({ row: { error, status } }: GridRenderCellParams<SyncDetails>) =>
          status && <SyncStatusInfo status={status} error={error} />,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ value: tags }: GridRenderCellParams<{ tags: string[] }, string[]>) => {
          if (tags?.length) {
            return <PaymentSystemTags tags={tags} />;
          }
        },
      },
      {
        field: 'actions',
        headerName: 'Action',
        minWidth: 105,
        sortable: false,
        renderCell: ({ row: { id, status } }: GridRenderCellParams<SyncDetails>) => (
          <Box display="flex" flexWrap="nowrap">
            <Box mr="4px">
              <ActionButton onClick={() => onEditClick(id)}>Edit</ActionButton>
            </Box>
            <Box>
              <ActionButton disabled={!isEntityNonSynced(status)} onClick={() => onResyncClick(id)}>
                {getSyncStatusButtonText(status)}
              </ActionButton>
            </Box>
          </Box>
        ),
      },
    ],
    [onResyncClick, onEditClick],
  );

  const filters = {
    childSystem: {
      id: 'child-system',
      label: 'Child system',
      options: filterChildSystems,
    },
    systemName: {
      id: 'system-name',
      label: 'System name',
      options: filterPaymentProviders,
    },
    tags: {
      id: 'tags',
      label: 'Tags',
      options: filterTags,
    },
    currencies: {
      id: 'currencies',
      label: 'Currencies',
      options: filterCurrencies,
    },
    status: {
      id: 'status',
      label: 'Status',
      options: filterStatuses,
    },
  };

  const handleFilterSubmit = (filters: Record<string, string[]>) => {
    setSearchParams(
      (prev) => {
        Object.keys(paymentSystemFilterKeys).forEach((key) => {
          const filterKey = key as PaymentSystemFilterKey;
          if (filters?.[filterKey]?.length) {
            prev.set(paymentSystemFilterKeys[filterKey], filters[key].join(','));
          } else {
            prev.delete(paymentSystemFilterKeys[filterKey]);
          }

          prev.delete(PaymentSystemSearchParam.Page);
        });

        return prev;
      },
      { replace: true },
    );
  };

  const handleFiltersReset = () => {
    setSortModel([]);
    setSearchParams(
      (prev) => {
        Object.values(PaymentSystemSearchParam).forEach((key) => {
          prev.delete(key);
        });

        return prev;
      },
      { replace: true },
    );
  };

  const handleFormNotificationClose = () => {
    setIsFormNotificationOpen(false);
  };

  return (
    <>
      <ActionBar>
        <AddButton onClick={onAddClick}>Add payment system</AddButton>
      </ActionBar>
      <FiltersBar
        filters={filters}
        defaultFilters={defaultFilters}
        onSubmit={handleFilterSubmit}
        onResetFilters={handleFiltersReset}
      />
      <DataGrid
        paginationMode="server"
        sortingMode="server"
        rowCount={pagination.total_count}
        paginationModel={{
          pageSize: pagination.per_page,
          page: pagination.current_page - 1,
        }}
        onPaginationModelChange={handlePaginationModelChange}
        onSortModelChange={handleSortingModelChange}
        sortModel={sortModel}
        columns={columns}
        rows={paymentSystems}
      />
      <ConfirmationDialog
        isOpen={isResyncConfirmationOpen}
        onClose={handleResyncConfirmationClose}
        title="Resync payment system"
        confirmButtonText="Resync"
        cancelButtonText="Cancel"
        onConfirm={handleResync}
        onCancel={handleResyncConfirmationClose}
      >
        Do you want to resync <HighlightedContentText>payment system?</HighlightedContentText>
      </ConfirmationDialog>
      <Drawer title={isEditMode ? 'Edit configuration' : 'New configuration'} open={isFormOpen} onClose={onCloseDrawer}>
        <PaymentSystemForm
          paymentSystemId={paymentSystemId}
          isEditMode={isEditMode}
          setIsFormDirty={setIsFormDirty}
          onClose={onCloseForm}
        />
      </Drawer>
      <ConfirmationDialog
        isOpen={isQuitFormConfirmationOpen}
        onClose={handleKeepEditingForm}
        title={`Quit ${isEditMode ? 'editing' : 'creating'} payment system?`}
        confirmButtonText="Quit"
        cancelButtonText={`Keep ${isEditMode ? 'editing' : 'creating'}`}
        onConfirm={handleQuitForm}
        onCancel={handleKeepEditingForm}
      >
        <WarningContentText>All entered data will be lost.</WarningContentText>
      </ConfirmationDialog>
      <NotificationBar type="success" isOpen={isFormNotificationOpen} onClose={handleFormNotificationClose}>
        {`Payment system has been ${isEditMode ? 'updated' : 'created'} successfully`}
      </NotificationBar>
    </>
  );
};

export default PaymentSystems;
