import { DELIMETER_REGEX, ListItemHeight, MAX_LIST_HEIGHT } from 'constants/multi-select';

import type { ChangeEvent, ForwardedRef, KeyboardEvent } from 'react';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { InputLabel, MenuItem, FormHelperText, type SxProps } from '@mui/material';
import { VariableSizeList } from 'react-window';
import type { FieldError } from 'react-hook-form';

import {
  MultiSelectContentBox,
  StyledSelect,
  StyledCheckbox,
  StyledDivider,
  StyledListSubheader,
  ListItemText,
  StyledChip,
  StyledFormControl,
  StyledTextField,
  StyledRestChip,
} from './MultiSelect.styled';

export type MultiSelectOption<T extends string | number> = {
  value: T;
  label: string;
  disabled?: boolean;
};

interface MultiSelectProps<T extends string | number> {
  options: MultiSelectOption<T>[];
  value: T[];
  onChange: (value: T[]) => void;
  label?: string;
  limitTags?: boolean;
  showAll?: boolean;
  showSearch?: boolean;
  error?: FieldError;
  required?: boolean;
  disabled?: boolean;
  sx?: SxProps;
}

export enum HelperOption {
  All = '______all_______',
  Divider = '______divider_______',
  Search = '______search_______',
}

const MultiSelectInner = <T extends string | number>(
  {
    value,
    onChange,
    options,
    label,
    limitTags = false,
    showAll = true,
    showSearch = true,
    error,
    required,
    disabled,
    sx,
  }: MultiSelectProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const valueSet = useMemo(() => new Set(value), [value]);

  const toggle = useCallback(() => {
    setOpen((state) => !state);
    setSearchText('');
  }, []);

  const filteredOptions = useMemo(() => {
    const terms = searchText
      .toLowerCase()
      .split(DELIMETER_REGEX)
      .filter((term) => term);

    return terms.length > 0
      ? options.filter((option) => terms.some((term) => option.label.toLowerCase().startsWith(term)))
      : options;
  }, [options, searchText]);

  const displayedOptions = useMemo(() => {
    if (!open) {
      return [];
    }

    let result = filteredOptions;

    if (showAll) {
      result = [
        {
          value: HelperOption.All as T,
          label: 'Select all',
        },
        {
          value: HelperOption.Divider as T,
          label: '',
        },
        ...result,
      ];
    }

    if (showSearch) {
      result = [
        {
          value: HelperOption.Search as T,
          label: '',
        },
        ...result,
      ];
    }

    return result;
  }, [open, showAll, showSearch, filteredOptions]);

  const handleMenuItemClick = useCallback(
    (option: T) => () => {
      if (option === HelperOption.All) {
        let newValue: T[] = [];
        const availableOptions = options.filter((o) => !o.disabled);

        if (availableOptions.length === options.length) {
          newValue = value.length === options.length ? [] : options.map((o) => o.value);
        } else {
          newValue =
            value.length === availableOptions.length || value.length === options.length
              ? options.filter((o) => o.disabled && valueSet.has(o.value)).map((o) => o.value)
              : options.filter((o) => !o.disabled || (o.disabled && valueSet.has(o.value))).map((o) => o.value);
        }

        onChange(newValue);

        return;
      }

      if (valueSet.has(option)) {
        const newValueSet = new Set(valueSet);
        newValueSet.delete(option);
        onChange(Array.from(newValueSet));
        return;
      }

      onChange([...value, option]);
    },
    [onChange, options, value, valueSet],
  );

  const handleDelete = useCallback(
    (item: T) => {
      const newValue = value.filter((v) => v !== item);
      onChange(newValue);
    },
    [onChange, value],
  );

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value), []);

  const handleSearchKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key !== 'Escape') {
      // Prevents autoselecting item while typing (default Select behaviour)
      e.stopPropagation();
    }
  }, []);

  const listHeight = useMemo(() => {
    let result = filteredOptions.length * ListItemHeight.Option;

    if (showSearch) {
      result += ListItemHeight.Search;
    }

    if (showAll) {
      result += ListItemHeight.Divider + ListItemHeight.Option;
    }

    if (result > MAX_LIST_HEIGHT) {
      return MAX_LIST_HEIGHT;
    }

    return result;
  }, [filteredOptions.length, showAll, showSearch]);

  const getItemSize = useCallback(
    (index: number) => {
      if (displayedOptions[index].value === HelperOption.Divider) {
        return ListItemHeight.Divider;
      }

      if (displayedOptions[index].value === HelperOption.Search) {
        return ListItemHeight.Search;
      }

      return ListItemHeight.Option;
    },
    [displayedOptions],
  );

  const ListWrapper = useMemo(
    () =>
      forwardRef<HTMLDivElement>(({ children, ...rest }, ref) => (
        <div ref={ref} {...rest}>
          {showSearch ? (
            <StyledListSubheader>
              <StyledTextField
                size="small"
                autoFocus
                fullWidth
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeyDown}
              />
            </StyledListSubheader>
          ) : null}

          {children}
        </div>
      )),
    [handleSearchChange, handleSearchKeyDown, showSearch],
  );

  const Row = useCallback(
    ({ index, style, data }) => {
      const option = data[index];

      if (option.value === HelperOption.Search) {
        return null;
      }

      if (option.value === HelperOption.All) {
        return (
          <MenuItem
            key={option.value}
            sx={style}
            value={option.value}
            onClick={handleMenuItemClick(option.value)}
            disableGutters
            selected={value.length > 0}
          >
            <StyledCheckbox
              checked={value.length > 0}
              indeterminate={value.length > 0 && value.length !== options.length}
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        );
      }

      if (option.value === HelperOption.Divider) {
        return (
          <div style={style}>
            <StyledDivider />
          </div>
        );
      }

      return (
        <MenuItem
          key={option.value}
          sx={style}
          value={option.value}
          onClick={handleMenuItemClick(option.value)}
          disableGutters
          selected={valueSet.has(option.value)}
          disabled={option.disabled}
        >
          <StyledCheckbox checked={valueSet.has(option.value)} />
          <ListItemText primary={option.label} />
        </MenuItem>
      );
    },
    [handleMenuItemClick, options.length, value.length, valueSet],
  );

  return (
    <>
      <StyledFormControl sx={sx} size="small" fullWidth error={!!error} disabled={disabled} required={required}>
        <InputLabel shrink={true}>{label}</InputLabel>
        <StyledSelect
          notched
          label={label}
          ref={ref}
          multiple
          value={value}
          MenuProps={MenuProps}
          renderValue={renderValue({
            options,
            onDelete: handleDelete,
            disabled,
            limitTags,
          })}
          open={open}
          onOpen={toggle}
          onClose={toggle}
        >
          <VariableSizeList
            height={listHeight}
            itemCount={displayedOptions.length}
            itemSize={getItemSize}
            width="100%"
            itemData={displayedOptions}
            innerElementType={ListWrapper}
            style={{ overflowX: 'hidden' }}
          >
            {Row}
          </VariableSizeList>
        </StyledSelect>
      </StyledFormControl>
      {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
    </>
  );
};

const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: 350,
      overflow: 'hidden',
    },
  },
};

interface RenderValue<T extends string | number> {
  options: MultiSelectOption<T>[];
  onDelete: (value: T) => void;
  disabled?: boolean;
  limitTags?: boolean;
}

function renderValue<T extends string | number>({ options, onDelete, disabled, limitTags }: RenderValue<T>) {
  return (value: T[]) => {
    if (options.length === value.length) {
      return 'All';
    }

    const valueOptions = options.filter((option) => value.includes(option.value));
    let displayValueOptions = valueOptions;
    let rest = [];

    if (limitTags) {
      displayValueOptions = valueOptions.slice(0, 1);
      rest = valueOptions.slice(1);
    }

    return (
      <MultiSelectContentBox limitTags={limitTags}>
        {displayValueOptions.map((option) => (
          <StyledChip
            size="small"
            key={option.value}
            label={option.label}
            disabled={option.disabled}
            onDelete={disabled ? undefined : () => onDelete(option.value)}
            onMouseDown={(event) => event.stopPropagation()}
          />
        ))}
        {rest.length ? <StyledRestChip size="small" label={`+${rest.length}`} /> : null}
      </MultiSelectContentBox>
    );
  };
}

export const MultiSelect = forwardRef(MultiSelectInner) as <T extends string | number>(
  props: MultiSelectProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof MultiSelectInner>;
