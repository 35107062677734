import { combineEpics, type Epic } from 'redux-observable';
import type { RootAction } from 'types/sotre/actions/root';
import type { RootState } from 'types/sotre/state/root';
import { loadCasinosAction } from 'api/casinos';
import { loadCurrenciesAction } from 'api/currencies';
import { loadPaymentProviders } from 'api/payment-providers';
import { filter, mergeMapTo } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { loadTrustedLevelsAction } from 'api/trsusted-level';
import { loadOriginsAction } from 'api/origins';
import { loadLicensesAction } from 'api/licenses';

import { initCashflowFormAction } from './actions';

const initCashflowFormEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(initCashflowFormAction)),
    mergeMapTo([
      loadPaymentProviders(),
      loadCurrenciesAction(),
      loadCasinosAction(),
      loadTrustedLevelsAction(),
      loadOriginsAction(),
      loadLicensesAction(),
    ]),
  );

export default combineEpics(initCashflowFormEpic);
