import {
  DEFAULT_CASINO,
  DEFAULT_RESTRICTIONS,
  INITIAL_PAYMENT_SYSTEM,
  PROVIDERS_WITH_DEFAULT_ACCESS_DATA,
} from 'constants/payment-system';
import { CASHOUT, DEPOSIT } from 'constants/limits';
import { PROVIDERS_WITH_PSP_SERVICE, PSP_SERVICE } from 'constants/access-data';

import type { FC } from 'react';
import React, { useCallback, useEffect } from 'react';
import { Grid, type SxProps, type Theme } from '@mui/material';
import {
  getDefaultValueByFieldType,
  type Casino,
  type CassinoSetting,
  type Limit,
  type PaymentSystem,
  type PaymentSystemCreateModel,
  type PaymentSystemUpdateModel,
} from 'types/api';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDispatch, useSelector } from 'react-redux';
import { isSaveCompletedSelector, paymentSystemErrorSelector } from 'store/payment-system-form/selectors';
import { tagsResponseSelector } from 'api/tags';
import {
  createPaymentSystemAction,
  forceLoadPaymentSystemAction,
  updatePaymentSystemAction,
} from 'api/payment-systems';
import type { FormSelectOption } from 'components/atoms/FormSelect/FormSelect';
import { initPaymentSystemFormAction } from 'store/payment-system-form';
import { limitsResponseSelector } from 'api/limits';
import { currenciesResponseSelector } from 'api/currencies';
import { paymentSystemSelector } from 'store/payment-system/selectors';
import { casinosResponseSelector } from 'api/casinos';
import { accessDataResponseSelector, forceLoadAccessDataAction } from 'api/access-data';
import FormContainer from 'components/molecules/FormContainer';
import { paymentProvidersOptionsSelector, systemNamesOptionsSelector } from 'store/payment-providers/selectors';
import FormButton from 'components/atoms/FormButton';
import { getUniqueValuesByDelimiter } from 'utils/getUniqueValuesByDelimiter';

import { GeneralSection, ProviderSection, CurrenciesSection, LimitsSection, CasinoSection } from './subcomponents';
import { createPaymentSystemSchema } from './payment-system-schema';
import { AccessDataSection } from './subcomponents/AccessDataSection/AccessDataSection';

interface PaymentSystemFormProps {
  paymentSystemId?: number;
  isEditMode: boolean;
  setIsFormDirty: (dirty: boolean) => void;
  onClose: () => void;
}

const buttonStyles: SxProps<Theme> = { m: '12px 24px 12px auto' };

export const PaymentSystemForm: FC<PaymentSystemFormProps> = ({
  paymentSystemId,
  isEditMode,
  setIsFormDirty,
  onClose,
}) => {
  const dispatch = useDispatch();

  const paymentSystem = useSelector(paymentSystemSelector);
  const accessData = useSelector(accessDataResponseSelector);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    unregister,
    reset,
    trigger,
    setError,
    setFocus,
    formState: { isDirty, errors, isSubmitting, isSubmitted },
  } = useForm<PaymentSystem>({
    mode: 'onSubmit',
    defaultValues: INITIAL_PAYMENT_SYSTEM,
    values: paymentSystem,
    resolver: zodResolver(createPaymentSystemSchema(isEditMode, accessData)),
  });

  const providerNames: FormSelectOption[] = useSelector(paymentProvidersOptionsSelector);
  const tags: string[] = useSelector(tagsResponseSelector);
  const currenciesResponse = useSelector(currenciesResponseSelector);
  const currencies = currenciesResponse.currencies;
  const protocols = currenciesResponse.protocols;
  const casinos: Casino[] = useSelector(casinosResponseSelector);
  const defaultLimits = useSelector(limitsResponseSelector);
  const isSaveCompleted = useSelector(isSaveCompletedSelector);
  const paymentSystemErrors = useSelector(paymentSystemErrorSelector);

  const watchedProviderName = watch('providerName', paymentSystem.providerName);
  const watchedUserTags = watch('tags.applied', paymentSystem.tags.applied);
  const watchedExcludedTags = watch('tags.excluded', paymentSystem.tags.excluded);
  const watchedCurrencies = watch('currencies', paymentSystem.currencies);
  const watchedLimits = watch('limits', paymentSystem.limits);
  const watchedProtocols = watch('protocols', paymentSystem.protocols);
  const watchedCasinos = watch('casinos', paymentSystem.casinos);
  const hideSensitiveData = watch('hideSensitiveData');
  const includePspService = watch('includePspService');
  const systemNames: FormSelectOption[] = useSelector(systemNamesOptionsSelector(watchedProviderName));
  const watchedPaymentGroups = watch('paymentGroups');
  const watchedExcludedPaymentGroups = watch('excludedPaymentGroups');
  const watchedCasinoSettings = watch('casino_settings');

  useEffect(() => {
    if (watchedPaymentGroups && watchedExcludedPaymentGroups && isSubmitted) {
      trigger('excludedPaymentGroups');
      trigger('paymentGroups');
    }
  }, [watchedPaymentGroups, watchedExcludedPaymentGroups, isSubmitted, trigger]);

  useEffect(() => {
    dispatch(initPaymentSystemFormAction());

    if (isEditMode && paymentSystemId) {
      dispatch(forceLoadPaymentSystemAction(paymentSystemId));
    }
  }, [dispatch, isEditMode, paymentSystemId]);

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty, setIsFormDirty]);

  useEffect(() => {
    if (paymentSystemErrors?.restrictions?.length > 0) {
      setError('restrictions', {
        message: paymentSystemErrors.restrictions[0],
      });

      setFocus('restrictions');
    }
  }, [paymentSystemErrors, setError, setFocus]);

  useEffect(() => {
    if (isSaveCompleted) {
      reset(undefined, { keepValues: true, keepDirty: false });
      onClose();
    }
  }, [isSaveCompleted, onClose, reset]);

  useEffect(() => {
    if (
      PROVIDERS_WITH_DEFAULT_ACCESS_DATA.includes(watchedProviderName) &&
      !isEditMode &&
      accessData?.fields?.length > 0
    ) {
      const casinoKeys = Object.keys(watchedCasinoSettings);

      accessData.fields
        .filter((field) => !!field.default_value)
        .forEach((field) => {
          casinoKeys.forEach((casinoKey) => {
            setValue(`casino_settings.${casinoKey}.access_data.${field.name}`, field.default_value);
          });
        });
    }
  }, [accessData, isEditMode, watchedCasinoSettings, setValue, watchedProviderName]);

  const userTags = tags.map((tag) => {
    return { value: tag, label: tag, disabled: watchedExcludedTags.includes(tag) };
  });

  const excludedTags = tags.map((tag) => {
    return { value: tag, label: tag, disabled: watchedUserTags.includes(tag) };
  });

  let selectedCasinos = watchedCasinos.map((id) => casinos.find((c) => c.id === id)).filter((c) => !!c);

  if (!isEditMode) {
    selectedCasinos = [DEFAULT_CASINO, ...selectedCasinos];
  }

  const onSubmit: SubmitHandler<PaymentSystem> = (data) => {
    const limits = Object.entries(data.limits).map(([key, value]) => ({
      currency: key,
      protocols: data.protocols[key] ?? [],
      data: value,
    }));

    let casinoSettings: CassinoSetting[] = [];
    if (accessData?.fields?.length > 0) {
      casinoSettings = Object.keys(data.casino_settings)
        .filter((casinoId) => Number(casinoId) > 0)
        .map((casinoId) => {
          const casinoData = data.casino_settings[casinoId];
          const casinoSetting: CassinoSetting = {
            access_data: {},
            casino_id: Number(casinoId),
            payment_system_fee: casinoData.payment_system_fee,
            payment_system_fee_currency: casinoData.payment_system_fee_currency,
            payment_system_fee_percent: casinoData.payment_system_fee_percent,
          };
          const casinoAccessData = casinoData.access_data;
          accessData.fields.forEach(
            (field) =>
              (casinoSetting.access_data[field.name] =
                casinoAccessData[field.name] ?? getDefaultValueByFieldType(field.type)),
          );

          return casinoSetting;
        });
    }

    const paymentSystemData = {
      name: data.systemName,
      tags: data.tags.applied,
      excluded_tags: data.tags.excluded,
      restrictions: data.restrictions || DEFAULT_RESTRICTIONS,
      available_currencies: data.currencies,
      limits,
      casino_settings: casinoSettings,
      payment_groups: getUniqueValuesByDelimiter(data.paymentGroups),
      excluded_payment_groups: getUniqueValuesByDelimiter(data.excludedPaymentGroups),
    };

    if (isEditMode) {
      const paymentSystem: PaymentSystemUpdateModel = {
        ...paymentSystemData,
        id: data.id,
      };

      dispatch(updatePaymentSystemAction(paymentSystem));
    } else {
      const paymentSystem: PaymentSystemCreateModel = {
        ...paymentSystemData,
        payment_system_name: data.providerName,
        child_system_name: data.systemName,
        job_name: data.jobName ?? '',
        test_mode: data.testMode,
      };

      dispatch(createPaymentSystemAction(paymentSystem));
    }
  };

  const onCurrenciesChange = (currencies: string[]) => {
    const deletedCurrencies = watchedCurrencies.filter((currency) => !currencies.includes(currency));
    const newCurrencies = currencies.filter((currency) => !watchedCurrencies.includes(currency));
    const newLimits = { ...watchedLimits };
    const newProtocols = { ...watchedProtocols };

    for (const currency of deletedCurrencies) {
      delete newLimits[currency];
      delete newProtocols[currency];
    }

    for (const currency of newCurrencies) {
      if (Object.keys(newLimits).length > 0) {
        newLimits[currency] = {
          ...watchedLimits.default,
        };
      }

      newProtocols[currency] = [];
    }

    setValue('currencies', currencies, { shouldDirty: true });
    setValue('limits', newLimits, { shouldDirty: true });
    setValue('protocols', newProtocols, { shouldDirty: true });

    if (isSubmitted) {
      trigger('limits');
    }
  };

  const onCasinosChange = (casinoIds: number[]) => {
    const defaultAccessData = getValues(`casino_settings.${DEFAULT_CASINO.id}`);
    const deletedCasinos = watchedCasinos.filter((casinoId) => !casinoIds.includes(casinoId));
    const newCasinoIds = casinoIds.filter((casinoId) => !watchedCasinos.includes(casinoId));

    setValue('casinos', casinoIds, { shouldDirty: true, shouldValidate: isSubmitted });

    newCasinoIds.forEach((casinoId) =>
      setValue(`casino_settings.${casinoId}`, defaultAccessData, {
        shouldDirty: true,
        shouldValidate: isSubmitted,
      }),
    );

    deletedCasinos.forEach((casinoId) => {
      unregister(`casino_settings.${casinoId}`);
    });
  };

  const onProviderChange = (providerName: string) => {
    dispatch(forceLoadAccessDataAction(providerName));

    const defaultProviderLimits = defaultLimits ? defaultLimits[providerName] ?? {} : {};
    let limits = { ...watchedLimits };

    if (Object.keys(limits).length > 0) {
      Object.keys(limits).forEach((key) => {
        const previousLimits = limits[key];

        const newLimits = Object.keys(defaultProviderLimits).reduce((acc: { [key: string]: Limit }, fieldKey) => {
          acc[fieldKey] = {
            deposit:
              DEPOSIT in defaultProviderLimits[fieldKey]
                ? previousLimits[fieldKey]?.deposit ?? defaultProviderLimits[fieldKey].deposit
                : undefined,
            cashout:
              CASHOUT in defaultProviderLimits[fieldKey]
                ? previousLimits[fieldKey]?.cashout ?? defaultProviderLimits[fieldKey].cashout
                : undefined,
          };

          return acc;
        }, {});

        limits[key] = { ...newLimits };
      });
    } else {
      limits = {
        default: { ...defaultProviderLimits },
      };

      for (const currency of watchedCurrencies) {
        limits[currency] = {
          ...limits.default,
        };
      }
    }

    setValue('providerName', providerName, { shouldDirty: true, shouldValidate: true });
    setValue('limits', limits, { shouldDirty: true });
    setValue('protocols', {}, { shouldDirty: true });
    Object.keys(watchedCasinoSettings).forEach((casinoId) => {
      unregister(`casino_settings.${casinoId}.access_data`);
    });

    if (isSubmitted) {
      trigger('limits');
    }
  };

  const onChangeLimits = () => {
    if (isSubmitted) {
      trigger('limits');
    }
  };

  const onImportAccessData = () => {
    const skipPspService = PROVIDERS_WITH_PSP_SERVICE.includes(watchedProviderName) && !includePspService;
    selectedCasinos.forEach((casino) => {
      const casinoData = accessData?.existing?.find((x) => x.casino_id === casino.id);

      if (casinoData) {
        Object.entries(casinoData.access_data).forEach(([key, value]) => {
          if (key === PSP_SERVICE && skipPspService) return;

          setValue(`casino_settings.${casinoData.casino_id}.access_data.${key}`, value, {
            shouldDirty: true,
            shouldValidate: isSubmitted,
          });
        });
      }
    });
  };

  const validateAccessData = useCallback(
    (casinoId: number, fieldName: string) => {
      if (isSubmitted) {
        trigger(`casino_settings.${casinoId}.access_data.${fieldName}`);
      }
    },
    [isSubmitted, trigger],
  );

  const validateFeeFields = useCallback(
    (casinoId: number, fieldName: string) => {
      if (isSubmitted) {
        trigger(`casino_settings.${casinoId}.${fieldName}`);
      }
    },
    [isSubmitted, trigger],
  );

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container rowSpacing={2} columnSpacing={2} sx={{ m: 0, width: '100%' }}>
        <ProviderSection
          control={control}
          providerNames={providerNames}
          isEditMode={isEditMode}
          errors={errors}
          onProviderChange={onProviderChange}
        />

        <GeneralSection
          control={control}
          systemNames={systemNames}
          userTags={userTags}
          excludedTags={excludedTags}
          paymentProvider={watchedProviderName}
          isEditMode={isEditMode}
          errors={errors}
        />

        <CasinoSection
          control={control}
          casinos={casinos}
          isEditMode={isEditMode}
          errors={errors}
          onCasinosChange={onCasinosChange}
        />

        <CurrenciesSection control={control} onCurrenciesChange={onCurrenciesChange} currencies={currencies} />

        <LimitsSection
          control={control}
          limits={watchedLimits}
          currencies={currencies}
          protocols={protocols}
          paymentProvider={watchedProviderName}
          errors={errors}
          onChangeLimits={onChangeLimits}
        />

        <AccessDataSection
          control={control}
          fields={accessData?.fields ?? []}
          casinos={selectedCasinos}
          currencies={currencies}
          paymentProvider={watchedProviderName}
          hideSensitiveData={hideSensitiveData}
          errors={errors}
          disabeleImport={watchedCasinos.length === 0 || !watchedProviderName}
          onInmport={onImportAccessData}
          watch={watch}
          onValidateAccessData={validateAccessData}
          onValidateFees={validateFeeFields}
        />

        <FormButton type="submit" sx={buttonStyles} disabled={isSubmitting}>{`${
          isEditMode ? 'Update' : 'Create'
        } payment system`}</FormButton>
      </Grid>
    </FormContainer>
  );
};
