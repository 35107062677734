import type {
  Cashflow,
  CashflowChild,
  Commission,
  Commissions,
  CommmissionsColum,
  CommmissionsRow,
} from 'types/entities';

export const PROVIDERS_WITH_CURRENCIES_FIELDS = ['coinspaid'];
export const DEFAULT_TAB_NAME = 'default';
export const NEW_CHILD_NAME = 'new';

const INITIAL_COMMISSION: Commission = {
  value: 0,
  currency: '',
};

const INITIAL_PERCENT_COMMISSION: Commission = {
  value: 0,
};

const INITIAL_COMMISSIONS: Commissions = {
  commission_fixed: INITIAL_COMMISSION,
  commission_percent: INITIAL_PERCENT_COMMISSION,
  min_commission: INITIAL_COMMISSION,
  failed_commission_fixed: undefined,
  max_commission: undefined,
};

const INITIAL_EXTENDED_COMMISSIONS: Commissions = {
  ...INITIAL_COMMISSIONS,
  failed_commission_fixed: INITIAL_COMMISSION,
  max_commission: INITIAL_COMMISSION,
};

export const INITIAL_CHILD: CashflowChild = {
  optionChildSystemName: '',
  trustedLevels: [],
  origins: [],
  licenses: [],
  depositReceivedCurrencies: [],
  depositSentCurrencies: [],
  id: -1,
  commissions: {
    deposit: INITIAL_EXTENDED_COMMISSIONS,
    cashout: INITIAL_EXTENDED_COMMISSIONS,
    chargeback: INITIAL_COMMISSIONS,
    refund: INITIAL_COMMISSIONS,
    reversal: INITIAL_COMMISSIONS,
  },
};

export const INITIAL_CASHFLOW: Cashflow = {
  id: 0,
  acceptableCurrencies: [],
  casinos: [],
  childSystemName: '',
  jobName: '',
  name: '',
  providerName: '',
  currency: '',
  childs: [{ ...INITIAL_CHILD, optionChildSystemName: DEFAULT_TAB_NAME }],
};

export const COMMISSIONS_COLUMNS: CommmissionsColum[] = [
  {
    key: 'min_commission',
    label: 'Min',
  },
  {
    key: 'commission_fixed',
    label: 'Fixed',
  },
  {
    key: 'commission_percent',
    label: 'Percent, %',
  },
  {
    key: 'failed_commission_fixed',
    label: 'Failed',
  },
  {
    key: 'max_commission',
    label: 'Max',
  },
];

export const COMMISSIONS_ROWS: CommmissionsRow[] = [
  {
    key: 'deposit',
    label: 'Deposit',
  },
  {
    key: 'cashout',
    label: 'Cashout',
  },
  {
    key: 'refund',
    label: 'Refund',
  },
  {
    key: 'chargeback',
    label: 'Chargeback',
  },
  {
    key: 'reversal',
    label: 'Reversal',
  },
];

export const PERCENT_COMMISSIONS = ['commission_percent'];
