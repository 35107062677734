import { combineEpics } from 'redux-observable';

import paymentSystemFormEpics from './payment-system-form/epics';
import paymentSystemsEpics from './payment-systems';
import cashflowFormEpics from './cashflow-form/epics';
import snapshotFormEpics from './snapshot-form/epics';
import casinoSnapshotsFormEpics from './casino-snapshots-form/epics';
import cashflowsEpics from './cashflows/epics';

export default combineEpics(
  paymentSystemFormEpics,
  cashflowFormEpics,
  snapshotFormEpics,
  casinoSnapshotsFormEpics,
  paymentSystemsEpics,
  cashflowsEpics,
);
